import { mixpanelEvent } from '@/lib/mixpanels/mixpanel'
import useWindowDimensions from '@/lib/web-ui/useWindowDimensions'
import { Typography, useTheme as muiUseTheme } from '@mui/material'
import Image from 'next/image'
import React from 'react'
import { itemDTO } from 'src/api/main'
import { Subjects } from 'src/api/product'
import styled, { useTheme } from 'styled-components'
import { Swiper, SwiperSlide } from 'swiper/react'
import Icon from '../icon/Icon'
import { Plus, Setting, Sizes } from '../icon/IconList'
import { SwiperCustomButton } from './SwiperCustomButton'
import { ModalType } from './UserSourceStep'

export interface SelectedBookListProps {
	openSelectBooksModal: () => void
	selectBookList: itemDTO[]
	selectedBook: itemDTO
	setSelectedBook: (book: itemDTO) => void
	bottom: (node?: Element) => void
	setModalType: (modalType: ModalType) => void
	subject: Subjects
}

export const SelectedBookList: React.FC<SelectedBookListProps> = ({
	openSelectBooksModal,
	selectBookList,
	setSelectedBook,
	selectedBook,
	bottom,
	setModalType,
	subject,
}) => {
	const theme = useTheme()
	const muiTheme = muiUseTheme()

	const { width } = useWindowDimensions()
	const isMobile = width < 769

	return (
		<ListWrap>
			<SelectedBooksHeader>
				<SectionTitle>필요한 교재의 내신자료만 골라보세요!</SectionTitle>
				<div>
					<EditSelectBookBtn
						className={'edit-select-book-btn'}
						onClick={() => {
							setModalType('selectBooksModal')
							openSelectBooksModal()
							//mx-131
							mixpanelEvent(`Book Setting Clicked`, {
								'Button Type': 'BookSetting1',
								'Button Name': '교재설정',
							})
						}}>
						<Icon
							icon={Setting}
							size={Sizes.small}
							color={theme.new.colors.sol_gray_700}
						/>
						교재 설정
					</EditSelectBookBtn>
					<SwiperCustomButton
						nextClassName={'selectedBooks-button-next'}
						prevClassName={'selectedBooks-button-prev'}
					/>
				</div>
			</SelectedBooksHeader>
			<TopPaginationStyledSwiper
				slidesPerView="auto"
				spaceBetween={20}
				navigation={{
					nextEl: '.selectedBooks-button-next',
					prevEl: '.selectedBooks-button-prev',
				}}
				onSlideChange={() => mixpanelEvent('Product Recommendation Carousel Clicked')}>
				{selectBookList.map((favorite, i) => {
					return (
						<SwiperSlide key={`item-${i}`}>
							<BookList
								onClick={() => {
									setSelectedBook(favorite)
								}}
								key={favorite.id}
								className={favorite.id === selectedBook?.id ? 'active' : ''}>
								<div className={'book-cover'}>
									<Image
										unoptimized={true}
										alt="book_img"
										src={favorite.imageUrl}
										width={56}
										height={78}
									/>
								</div>

								<p className={'book-title'}>{favorite.title}</p>
								<div className={'picker'} />
							</BookList>
						</SwiperSlide>
					)
				})}
				<SwiperSlide key={`item-${selectBookList.length + 1}`}>
					<BookList
						onClick={() => {
							openSelectBooksModal()

							//mx-131
							mixpanelEvent(`Book Setting Clicked`, {
								'Button Type': 'BookSetting2',
								'Button Name': '추가하기',
							})
						}}>
						<div className={'book-cover add-btn'}>
							<Icon
								icon={Plus}
								size={isMobile ? Sizes.xmedium : Sizes.medium}
								color={muiTheme.palette.primary.main}
							/>
							<Typography variant="semibold16" color="primary">
								추가하기
							</Typography>
						</div>

						<div className={'picker'} />
					</BookList>
				</SwiperSlide>
			</TopPaginationStyledSwiper>
		</ListWrap>
	)
}
export const EditSelectBookBtn = styled.button`
	all: unset;
	display: flex;
	align-items: center;
	font-size: 14px;
	margin-right: 12px;
	cursor: pointer;
	font-weight: 600;
	color: ${(props) => props.theme.new.colors.sol_gray_700};

	i {
		margin-right: 4px;
	}
`
const SelectedBooksHeader = styled.div`
	display: flex;
	justify-content: space-between;

	> div {
		margin-bottom: 20px;
		display: flex;
	}
`
const ListWrap = styled.div`
	max-width: 1200px;
	margin: 0 auto;
	position: relative;

	.guid-tooltip-wrapper {
		top: calc(100% - 10px);
		left: 230px;
		z-index: 3;
		@media (max-width: 768px) {
			left: 170px;
		}
	}
`
export const SectionTitle = styled.h3`
	color: ${(props) => props.theme.new.colors.sol_gray_800};
	font-size: 24px;
	font-weight: 700;
	line-height: 32px;
	margin-bottom: 20px;

	span {
		margin-left: 8px;
		color: ${(props) => props.theme.new.colors.sol_gray_500};
		font-size: 14px;
		font-weight: 400;
		line-height: 22px;
	}

	@media (max-width: 768px) {
		margin-bottom: 16px;
		font-size: 16px;
		span {
			font-size: 14px;
		}
	}
`
export const BookList = styled.button`
	all: unset;
	display: flex;
	flex-direction: column;
	width: 140px;
	gap: 8px;
	align-items: center;
	cursor: pointer;

	&._pr {
		width: 120px;
	}

	.book-cover {
		position: relative;
		width: calc(100% - 20px);
		height: 120px;

		background-color: ${(props) => props.theme.new.colors.sol_gray_50};
		border-radius: 50%;
		border: 2px solid transparent;
		box-sizing: border-box;

		display: flex;
		align-items: center;
		justify-content: center;
		overflow: hidden;
	}

	.add-btn {
		flex-direction: column;
		gap: 4px;

		span {
			font-size: 14px;
		}
	}

	.b-sm {
		width: 100%;
	}

	.book-title {
		margin-top: 8px;
		-webkit-line-clamp: 2;
		white-space: normal;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		color: ${(props) => props.theme.new.colors.sol_gray_500};
		font-weight: 400;
		line-height: 1.5;
		text-align: center;
		height: 48px;
	}

	.pr-book-title {
		height: auto;
		margin: 0;
	}

	.picker {
		margin-top: 4px;
		//::after {
		content: '';
		display: block;
		width: 0;
		height: 0;
		//background-color: ${(props) => props.theme.new.colors.sol_indigo_500};
		border-bottom: 14px solid transparent;
		border-left: 7px solid transparent;
		border-right: 7px solid transparent;
		//}
	}

	:hover {
		.book-cover {
			background-color: ${(props) => props.theme.new.colors.sol_gray_100};
		}

		.book-title {
			color: ${(props) => props.theme.new.colors.sol_gray_700};
		}
	}

	&.active {
		.book-title {
			transition: all 0.3s ease-in-out;
			color: ${(props) => props.theme.new.colors.sol_indigo_500};
			font-weight: 600;
		}

		.book-cover {
			transition: all 0.3s ease-in-out;
			border: 2px solid ${(props) => props.theme.new.colors.sol_indigo_500};
			background-color: ${(props) => props.theme.new.colors.sol_indigo_50};
		}

		.b-sm {
			border: 1px solid ${(props) => props.theme.new.colors.sol_indigo_500};
		}

		.picker {
			transition: all 0.3s ease-in-out;
			border-bottom: 14px solid ${(props) => props.theme.new.colors.sol_indigo_500};
		}
	}

	@media (max-width: 768px) {
		width: 100px;
		.book-cover {
			height: 94px;
			width: 94px;

			img {
				width: 44px;
			}
		}

		.book-title {
			font-size: 14px;
			/* height: 40px; */
		}

		.picker {
			border-bottom: 7px solid transparent;
			border-left: 5px solid transparent;
			border-right: 5px solid transparent;
		}

		&.active {
			.picker {
				border-bottom: 7px solid ${(props) => props.theme.new.colors.sol_indigo_500};
			}
		}
	}
`
export const TopPaginationStyledSwiper = styled(Swiper)`
	//overflow: visible;

	.swiper-slide {
		flex-shrink: unset;
		//width: 140px !important;
	}

	.swiper-wrapper {
		width: fit-content;
	}
`

export const SlideWrap = styled.div`
	display: flex;
	flex-direction: row;
	overflow: visible;
	padding: 0;
	margin: 0 -12px;

	::-webkit-scrollbar {
		display: none;
	}

	.wrap-vertical::-webkit-scrollbar {
		display: none;
	}

	@media (max-width: 768px) {
		overflow: visible;
		padding: 0;
		margin: 0 -8px;
	}

	.alice-carousel {
		.alice-carousel__prev-btn,
		.alice-carousel__next-btn {
			position: absolute;
			top: -60px;
			width: 48px;
			height: 48px;
			padding: 0;
			border-radius: 2px;
			background-color: #000;
			transform: translateY(-50%);
			transition: opacity 0.2s;
			z-index: 9;

			&:hover {
				opacity: 0.75;
			}

			&-wrapper {
				height: 100%;
			}

			&-item {
				width: 100%;
				height: 100%;
				padding: 0;
				background: no-repeat 50% 50%;
				background-size: 22px;

				span {
					display: none;
				}
			}

			@media (max-width: 1200px) {
				top: -48px;
			}
			@media (max-width: 768px) {
				width: 36px;
				height: 36px;
				top: -36px;
			}
		}

		.alice-carousel__prev-btn {
			right: 72px;

			@media (max-width: 768px) {
				right: 54px;
			}

			&-item {
				background-image: url('/assets/left.svg');
			}
		}

		.alice-carousel__next-btn {
			right: 12px;

			@media (max-width: 768px) {
				right: 4px;
			}

			&-item {
				background-image: url('/assets/right.svg');
			}
		}
	}
`
