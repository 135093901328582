import { Tabs as AntdTabs } from 'antd'
import React, { useCallback, useMemo } from 'react'
import { Subjects } from 'src/api/product'
import styled from 'styled-components'
import { PromotionLinks } from './PromotionLink'
import { Badge, Button, Tabs } from '@bookips/solvook-ui-library'
import { useRouter } from 'next/router'
import { useTheme } from '@mui/material'
import { mixpanelEvent } from '@/lib/mixpanels/mixpanel'

export interface SubjectSectionProps {
	subject: Subjects
	setSubject: (subject: Subjects) => void
}

type TabType = {
	id: number
	key: Subjects
	label: React.ReactNode
}

const subjectTabList: Array<TabType> = [
	{
		id: 0,
		key: Subjects.EN,
		label: '영어',
	},
	{
		id: 1,
		key: Subjects.KO,
		label: '국어',
	},
	{
		id: 2,
		key: Subjects.MA,
		label: '수학',
	},
	{
		id: 3,
		key: Subjects.SC,
		label: '과학',
	},
	{
		id: 4,
		key: Subjects.SO,
		label: '사회',
	},
]
export const SubjectSection: React.FC<SubjectSectionProps> = ({ subject, setSubject }) => {
	const theme = useTheme()
	const router = useRouter()

	const initSubjectId = useMemo(() => {
		const findIndex = subjectTabList.findIndex((item) => item.key === subject)
		return findIndex !== -1 ? findIndex : subjectTabList[0].id
	}, [subject])

	const { tabsProps, changeTab } = Tabs.useTabs(initSubjectId)

	const handleClickTab = useCallback(
		(_, value) => {
			const currentSubject = subjectTabList[value].key as Subjects
			setSubject(currentSubject)
			changeTab(_, value)
			mixpanelEvent('Tab UI Clicked', {
				'Tab Type': 'Subject Home',
				'Tab Name': currentSubject,
			})
		},
		[changeTab, router, setSubject],
	)

	const handleConsultingClick = useCallback(() => {
		mixpanelEvent('Tab UI Clicked', {
			'Tab Type': 'Subject Home',
			'Tab Name': '입시 컨설팅',
		})
		window.open(`${process.env.NEXT_PUBLIC_APP_ENDPOINT}/event/consulting`, '_blank')
	}, [])

	return (
		<SubjectSectionWrapper>
			<div>
				<Tabs {...tabsProps} onChange={handleClickTab}>
					{subjectTabList.map((item, idx) => (
						<Tabs.Tab
							key={item.key}
							label={item.label}
							value={idx}
							sx={{
								[theme.breakpoints.down('sm')]: {
									...theme.typography.subtitle1,
									marginRight: '12px',
								},
							}}
						/>
					))}

					{/* 과목탭과 역할이 다르기 때문에 분리하였습니다. */}
					<Button
						variant="text"
						label={
							<>
								입시컨설팅
								<Badge
									customcolor="Red"
									badgeContent={'NEW'}
									sx={(theme) => ({
										width: '40px',
										height: '20px',
										span: { transform: 'initial' },
									})}
								/>
							</>
						}
						sx={(theme) => ({
							fontSize: 20,
							color: theme.palette.grey[300],
							fontWeight: 600,
							lineHeight: 1.25,
							padding: 0,
							'&.Mui-selected': {
								color: theme.palette.grey[800],
							},
							'&:hover': {
								color: theme.palette.grey[800],
							},

							[theme.breakpoints.down('sm')]: {
								...theme.typography.subtitle1,
							},
						})}
						onClick={handleConsultingClick}
					/>
				</Tabs>
				<PromotionLinks />
			</div>
		</SubjectSectionWrapper>
	)
}

export const TabsNav = styled(AntdTabs)`
	.ant-tabs-nav {
		margin-bottom: 0;

		::before {
			display: none;
		}
	}

	.ant-tabs-nav-wrap {
		.ant-tabs-ink-bar {
			background-color: ${(props) => props.theme.new.colors.gray900};
		}

		.ant-tabs-nav-list {
			.ant-tabs-tab {
				padding: 0;

				.ant-tabs-tab-btn {
					padding: 0 0 10px 0;
					font-size: 20px;
					line-height: 1.5;
					font-weight: 600;
					color: ${(props) => props.theme.new.colors.gray500};
				}
			}

			.ant-tabs-tab-active {
				.ant-tabs-tab-btn {
					color: ${(props) => props.theme.new.colors.gray900};
				}
			}

			.ant-tabs-tab + .ant-tabs-tab {
				margin-left: 20px;
			}
		}
	}

	@media (max-width: 768px) {
		.ant-tabs-nav-wrap {
			.ant-tabs-nav-list {
				.ant-tabs-tab {
					.ant-tabs-tab-btn {
						padding: 0 0 8px 0;
						font-size: 16px;
					}
				}

				.ant-tabs-tab + .ant-tabs-tab {
					margin-left: 12px;
				}
			}
		}
	}
`
const SubjectSectionWrapper = styled.section`
	display: flex;
	align-items: center;
	justify-content: center;
	border-bottom: 1px solid ${(props) => props.theme.new.colors.sol_gray_100};
	width: 100vw;
	margin-left: calc(50% - 50vw);
	background-color: #fff;

	> div {
		display: flex;
		width: 100%;
		justify-content: space-between;
		align-items: center;
		max-width: 1200px;
		//margin: 0 auto;

		.ant-tabs {
			margin-top: 12px;
			@media (max-width: 768px) {
				margin-top: 0;
			}
		}
	}

	@media (max-width: 1200px) {
		padding: 0 32px 0;
		width: calc(100% + 80px);
		margin: 0 -40px;
	}

	@media (max-width: 768px) {
		width: calc(100% + 32px);
		margin: 0 -16px;
		padding: 0 16px;
	}
`
