import { mixpanelEvent } from '@/lib/mixpanels/mixpanel'
import useWindowDimensions from '@/lib/web-ui/useWindowDimensions'
import React, { useMemo } from 'react'
import styled from 'styled-components'
import { SwiperOptions } from 'swiper'
import 'swiper/css/effect-cards'
import 'swiper/css/effect-fade'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Subjects } from '../../api/product'
import Icon from '../icon/Icon'
import { ArrowRight, Sizes } from '../icon/IconList'
import {
	EN_DEFAULT_LIST,
	KO_DEFAULT_LIST,
	MA_DEFAULT_LIST,
	SC_DEFAULT_LIST,
	SO_DEFAULT_LIST,
} from './LandingBooks'

interface SelectBooksBannerProps {
	openSelectBooksModal: () => void
	subject: Subjects
}

export interface ISlideItems {
	title: string
	imageUrl: string
}

const SUBJECT_MAP = {
	[Subjects.EN]: EN_DEFAULT_LIST,
	[Subjects.KO]: KO_DEFAULT_LIST,
	[Subjects.MA]: MA_DEFAULT_LIST,
	[Subjects.SO]: SO_DEFAULT_LIST,
	[Subjects.SC]: SC_DEFAULT_LIST,
} as const

const SelectBooksBanner: React.FC<SelectBooksBannerProps> = ({ openSelectBooksModal, subject }) => {
	interface ISlideOptions
		extends Pick<SwiperOptions, 'slidesPerView' | 'loop' | 'autoplay' | 'scrollbar'> {}

	const { width } = useWindowDimensions()
	const isMobile = width < 769
	const items: Array<ISlideItems> = useMemo(() => {
		return SUBJECT_MAP[subject] || EN_DEFAULT_LIST
	}, [subject])
	const onClick = () => {
		openSelectBooksModal()
		mixpanelEvent('Favorites Books Select Button Clicked', {
			subject: subject,
		})
	}
	return (
		<PageWrapper>
			<TextWrap>
				<b>
					<div>
						<Swiper
							slidesPerView={1}
							direction={'vertical'}
							autoplay={{ delay: 2200 }}
							speed={1000}>
							{items.map((book, index) => {
								return (
									<React.Fragment key={`book-title-${index}`}>
										<SwiperSlide key={`book-${index}`}>
											<strong>{book.title}</strong>
										</SwiperSlide>
									</React.Fragment>
								)
							})}
						</Swiper>
					</div>
					수업자료를 찾고 계신가요?
				</b>

				<p>
					<span>찾으시는 교재의 자료들을 모아놨어요</span>
				</p>
				<button onClick={onClick}>
					필요한 수업자료 찾기
					<Icon icon={ArrowRight} size={Sizes.small} color={'#fff'} />
				</button>
			</TextWrap>
			<div className={`swiper-wrapper-w ${subject}`}>
				<Swiper
					slidesPerView={1}
					autoplay={{ delay: 2200 }}
					speed={1000}
					breakpoints={{
						768: {
							spaceBetween: 48,
							width: 164,
						},
						0: {
							spaceBetween: 16,
							width: 64,
						},
					}}
					className={`best-book-swiper`}>
					{items.map((book, index) => {
						const imageUrl = book.imageUrl
						return (
							<SwiperSlide key={`book-${index}`}>
								<div>
									<PopularBook isMobile={isMobile}>
										<div
											className="book-cover"
											style={{ backgroundImage: `url(${imageUrl})` }}
										/>
									</PopularBook>
								</div>
							</SwiperSlide>
						)
					})}
				</Swiper>
			</div>
		</PageWrapper>
	)
}

export default SelectBooksBanner

const TextWrap = styled.div`
	width: 570px;

	strong {
		color: ${(props) => props.theme.new.colors.sol_indigo_500};
		font-weight: 600;
	}

	p {
		font-weight: 600;
		color: ${(props) => props.theme.new.colors.gray400};
		line-height: 1.5;
		margin-top: 16px;
	}

	b {
		font-weight: 700;
		font-size: 36px;
		line-height: 1.5;

		> div {
			display: flex;
			align-items: center;
			justify-content: flex-start;
		}

		.swiper {
			height: 59px;
			width: fit-content;
			margin-left: unset;
			margin-right: unset;

			strong {
				background-color: #fff;
				padding: 0 6px;
				border-radius: 6px;
				font-weight: 700;
				line-height: 135%;
				display: block;
				width: fit-content;
			}
		}
	}

	button {
		all: unset;
		padding: 12px 20px;
		border-radius: 6px;
		background-color: ${(props) => props.theme.new.colors.sol_indigo_500};
		color: #fff;
		font-size: 16px;
		font-weight: 600;
		line-height: 1.5;
		margin-top: 40px;
		display: flex;
		align-items: center;
		width: 158px;
		cursor: pointer;

		i {
			margin-left: 8px;
		}
	}

	@media (max-width: 768px) {
		b {
			font-size: 20px;

			.swiper {
				height: 30px;
			}
		}

		p {
			display: none;
		}

		button {
			bottom: 32px;
			position: absolute;
			left: 0;
			right: 0;
			margin: 0 auto;
			width: 80%;
			padding: 9px 16px;
			font-size: 14px;
			justify-content: center;
		}
	}
`
const PageWrapper = styled.div`
	display: flex;
	margin: 0 auto;
	align-items: center;
	max-width: 1200px;
	position: relative;
	padding-bottom: 48px;
	@media (max-width: 768px) {
		position: initial;
		padding-bottom: 62px;
		width: 280px;
		margin: unset;
		align-items: flex-start;
	}

	.swiper-wrapper-w {
		flex: 1;
		display: flex;
		height: 230px;

		&.KO {
			height: 210px;

			.book-cover {
				height: 210px;
			}

			@media (max-width: 768px) {
				height: 86px;
				.book-cover {
					height: 86px;
				}
			}
		}

		.swiper {
			transition: all 0.5s ease-in-out;
			position: absolute;
		}

		.swiper-slide:not(.swiper-slide-active, .swiper-slide-prev) {
			opacity: 0.3;
			transition: all 0.3s ease-in-out;
		}

		.swiper-slide-active {
			overflow: visible;

			.book-cover {
				box-shadow: 6px 6px 20px 0px rgba(28, 31, 46, 0.12);
			}
		}

		.swiper-slide-prev {
			opacity: 0;
			transition: all 0.3s ease-out;
		}

		@media (max-width: 768px) {
			height: 94px;
		}
	}
`

const PopularBook = styled.div<{ isMobile: boolean }>`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	box-sizing: border-box;
	position: relative;

	.book-cover {
		background-color: ${(props) => props.theme.new.colors.white};
		background-size: cover;
		background-position: center;
		width: 164px;
		box-sizing: border-box;
		border-radius: 6px;
		height: 230px;
		margin-bottom: 20px;
	}

	@media (max-width: 768px) {
		.book-cover {
			width: 67px;
			height: 94px;
		}
	}
`
